import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import TopSection from '../components/TopSection'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import {
    Link
} from 'react-router-dom'
import {
    fetchTeam
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import {Helmet} from "react-helmet";

class Team extends React.Component{

    componentDidMount(){
        this.props.fetchTeam()
    }

    renderList(){
        return this.props.team.map((item, index) => {
            return (
                <Col md={4} sm={6} xs={12} xl={3} key={index}>
                    <div className="ldb_item">
                        <figure>
                            <img src={`${BASE_URL}${item.image}`} alt={`${item.firstname} ${item.position}`} />
                            <figcaption>
                                <h4 className="ldb_title">{item.firstname}</h4>
                                <small className="ldb_description">{item.position}</small>
                            </figcaption>
                        </figure>
                        <Link to={`/team/${item.search_name}`} title="View Profile" />
                    </div>
                </Col>
            )
        })
    }

    render(){
        if(!this.props.team) {return <></>}

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "CollectionPage",
                "@id": "https://www.ldbconstruction.com/team/#webpage",
                "url": "https://www.ldbconstruction.com/team/",
                "name": "Our Team - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "inLanguage": "en-US"
              }
            ]
        }
        
        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Our Team - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="We have a dynamic, professional and experienced management team working alongside handpicked master craftsmen in order to deliver a bespoke service and superlative standards of construction, design & furnishings."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <TopSection 
                        heading="team"
                        text="We have a dynamic, professional and experienced management team working alongside handpicked master craftsmen in order to deliver a bespoke service and superlative standards of construction, design & furnishings. We have a direct team spanning two decades and have a reputation for attracting London’s best talent. We have virtually no turnover of staff."
                        backgroundColor="#F7F7F7" 
                    />
                    <section className="ldb_team-section" style={{ backgroundColor: `#F7F7F7`}}>
                        <Container>
                            <Row>
                                {this.renderList()}
                            </Row>
                        </Container>
                    </section>  
                </Nav>     
                <Footer />         
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        team: state.team
    }
}

export default connect(mapStateToProps, {
    fetchTeam
})(Team)