import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";

//images
import video from '../assets/videos/LDB-Wellness-Process-01.mp4'

class Covid extends React.Component{

    EmbedVideo = () => {
        return (
            <div  className="ldb_video" dangerouslySetInnerHTML={{ __html: `
                <video title="ldbYoutubeVideo" width="560" height="315" autoplay loop playsinline controls>
                <source src=${video} type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            ` }}></div>
        )
    }

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/covid/#webpage",
                "url": "https://www.ldbconstruction.com/covid/",
                "name": "Covid - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2014-02-26T15:20:19+00:00",
                "dateModified": "2020-12-20T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/Covid/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Wellness - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is a luxury residential building refurbishment and maintenance company, specialising in standard-bearing design and build solutions for exclusive clients in prime areas of central London."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <TopSection 
                        heading="wellness"
                        text="The health and Safety of our staff is fundamental to the success of our business.
                        All of our staff receive comprehensive training that is continually updated in accordance with current guidelines and evolving legislation.
                        CSCS cards are compulsory on all of our sites and all of our sites have a qualified first aider and SMSTS operative present as a minimum.
                        We actively encourage and promote wellness and vitality."
                    >
                      <p>Health, safety and the wellness of our staff and our environment are fundamental to the continued success of our business.</p>
                      <p>We believe in the 6 pillars of employee wellbeing, job security, financial security, health, support, protection and work-life balance. </p>
                      <p>We have always believed that this ethos is derived from the idea that employee performance is linked to these six key areas of wellbeing in the workplace. </p>
                      <p>With our large direct team of employees that make up the LDB family, we have set ourselves firmly apart from our competition and we continue to invest and commit to this way of working in all that we do.</p>
                    </TopSection>
                    <section className="ldb_about-section">
                        
                      <Container>
                          <Row>
                              <Col xs={12}>
                                  {this.EmbedVideo()}
                              </Col>
                          </Row>
                      </Container>
                  </section> 
                    
                </Nav>      
                <Footer />
            </React.Fragment>
        )
    }
}

export default Covid