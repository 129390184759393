import React from 'react'
import Nav from '../components/Nav'
import SustainabilityTopSection from '../components/SustainabilityTopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import SectionHeading from '../components/SectionHeading'

//images
import sustainabilityImg1 from '../assets/img/sustainability/sustainability-hero@2x.jpg'

class Sustainability extends React.Component{

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/sustainability/#webpage",
                "url": "https://www.ldbconstruction.com/sustainability/",
                "name": "Sustainability Mission Statement - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2024-07-02:20:19+00:00",
                "dateModified": "2024-07-02T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/sustainability/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Sustainability Mission Statement - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB is committed to contributing to the creation of a sustainable future for all. We are constantly reviewing our business practices to ensure environmentally friendly solutions."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <SustainabilityTopSection 
                        heading="sustainability mission statement"
                        text="LDB is committed to contributing to the creation of a sustainable future for all. We are constantly reviewing our business practices to ensure environmentally friendly solutions."
                    />
                    <section className="ldb_sustainability-section">
                      <Container>
                          <Row className="ldb_sustainability-bodycopy">
                              <Col xs={12}>
                                  <p>LDB is committed to contributing to the creation of a sustainable future for all. We are constantly reviewing our business practices to ensure environmentally friendly solutions.</p>

                                  <p className="mb-4">We have set targets for our teams to reduce energy and water usage and achieve zero avoidable wastage and zero avoidable pollution. Wherever possible, we source sustainably produced products that can be recycled or are biodegradable. We also always aim to source products that are durable and reusable.</p>
                              </Col>
                          </Row> 
                          <Row className="ldb_sustainability-bodycopy">
                              <Col xs={12} md={4}>
                                  <SectionHeading text="These are some of our actions in 2024:" />
                              </Col>
                              <Col xs={12} md={8}>
                                  <ul>
                                    <li>We have replaced plastic rubble bags with woven reusable poly textile rubble sacks, thereby saving 50,000 plastic rubble bags a year.</li>
                                    <li>We now have an effective recycling agreement in place for our Correx board with The Plastic Expert and for all paper and packaging used on-site and in our offices with Ecogen Recycling. This recycles approximately 5,000 Correx sheets and 3 tonnes of paper and cardboard a year.</li>
                                    <li>We aim to have reclaimed materials make up 5% of the total project materials by 2025. To achieve this, we are actively seeking out architects and designers with the same targets.</li>
                                    <li>In our purchasing department, we give preference to products with recycled content and the lowest toxicity.</li>
                                    <li>Wherever possible, we will always specify composite materials. (Composite materials are the combination of two or more materials so that the properties of the final material are superior to those of the two components separately).</li>
                                  </ul>
                              </Col>
                          </Row>
                          <Row className="ldb_sustainability-bodycopy">
                              <Col xs={12}> 
                                  <p>Actions like these ensure everyone at LDB supports our sustainability goals and that our mission to become London’s greenest high-end super-prime residential contractor is achieved.</p>

                                  <p className="mb-4">Our ongoing focus on the well-being of our staff and contributing to a better world is the driver behind everything we do.</p>
                                  <div className="ldb_horline"></div>
                              </Col>
                          </Row> 
                          <Row>
                              <Col xs={12} className="ldb_sustainability-images">
                                <img src={sustainabilityImg1} alt="Sustainability Mission Statement LDB" />
                              </Col>
                          </Row>
                      </Container>
                  </section> 
                    
                </Nav>      
                <Footer />
            </React.Fragment>
        )
    }
}

export default Sustainability