import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {Helmet} from "react-helmet";
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import SectionHeading from '../components/SectionHeading'
//images
import aftercare1 from '../assets/img/aftercare/04-aftercare@2x.jpg'
import aftercare2 from '../assets/img/aftercare/06-aftercare@2x.jpg'
import aftercare3 from '../assets/img/aftercare/07-aftercare@2x.jpg'
import aftercare from '../assets/img/aftercare/01-aftercare@2x.jpg'

class Aftercare extends React.Component{
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "CollectionPage",
                "@id": "https://www.ldbconstruction.com/aftercare/#webpage",
                "url": "https://www.ldbconstruction.com/aftercare/",
                "name": "Aftercare - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "inLanguage": "en-US"
              }
            ]
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Aftercare - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="Welcome to LDB Property Management, a subsidiary of LDB Construction UK Ltd. At LDB Property Management, we offer a unique and tailored property management service to our valued customers."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <TopSection 
                        heading="aftercare"
                        text="At LDB Property Management, we offer a unique and tailored property management service to our valued customers. Our focus is on providing property-specific planned management coupled with an immediate, round-the-clock and highly responsive service. We offer a trusted, professional and discreet service covering all aspects of prime residential property management. Our services are delivered by permanently employed, highly skilled engineers who will become intimately familiar with the workings of your home, all of whom have been DBS and CRB certified."
                    />

                    <section className="ldb_aftercare-section">
                      <Container>
                          <Row>
                              <Col xs={12}>
                                  <figure className="ldb_aftercare-figure">
                                      <img src={aftercare} alt="Aftercare LDB" />
                                  </figure>
                              </Col>
                          </Row>
                          <Row className="ldb_aftercare-bodycopy">
                              <Col xs={12} md={4}>
                                  <SectionHeading text="end-to-end management and property maintenance" />
                              </Col>
                              <Col xs={12} md={8}>
                                  <p>As a result of our parent company LDB Construction, our small works division is able to deal with any small building works or alterations that may be required, whether it is redecorating an entire home, re-fitting a bathroom or installing a replacement kitchen.</p>

                                  <p>We also offer an exclusive property maintenance service for discerning clients who may require more bespoke and specific requirements.</p>

                                  <p className="mb-4">We understand how to work in furnished and occupied high-value homes and we understand the importance of discretion and confidentiality. Our reputation for transparency and excellence in the London prime central market is exceptionally well established.</p>
                                  <div className="ldb_horline"></div>
                              </Col>
                              
                          </Row>
                          <Row>
                              <Col xs={12} className="ldb_aftercare-images">
                                <img src={aftercare2} alt="Aftercare LDB 1" />
                              </Col>
                              <Col xs={12} className="ldb_aftercare-images">
                                <img src={aftercare3} alt="Aftercare LDB 2" />
                              </Col>
                              <Col xs={12} className="ldb_aftercare-images">
                                <img src={aftercare1} alt="Aftercare LDB 3" />
                              </Col>
                          </Row>
                      </Container>
                  </section>
                </Nav> 
                <Footer />               
            </React.Fragment>
        )
    }
}

export default Aftercare